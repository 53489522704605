// DOM Cache Manager
export class DOMCache {
  constructor() {
    // Initialize all DOM references as null
    this.appContainer = null;
    this.yearSpans = null;
    this.interBubble = null;
    this.aiLink = null;
    this.aiMsg = null;
    this.mainCard = null;
    this.betaSignup = null;
    this.closeButton = null;
    this.openButton = null;
    this.aboutContainer = null;
    this.moreAbout = null;
    this.learnMore = null;
    this.stageTitle = null;
    this.startButton = null;
    this.startLink = null;
    this.signupContainer = null;
    this.startContainer = null;
    this.signupStageBlocks = null;
    this.beginButton = null;

    // Form-specific elements
    this.formSlides = null;
    this.formNextButtons = null;
    this.formBackButtons = null;
    this.formInputControls = null;
    this.documentElement = document.documentElement;

    // Stage-specific elements
    this.stageBlocks = null;
    this.stageEmail = null;
    this.stagePhone = null;
    this.stageName = null;
    this.stagePassword = null;

    // Form Element By ID
    this.emailAddress = null;
    this.verifyEmail = null;
    this.cellPhone = null;
    this.verifyPhone = null;
    this.firstName = null;
    this.lastName = null;
    this.password = null;
    this.passConfirm = null;

    // Initialize cache
    this.initCache();
  }

  initCache() {
    // Cache all frequently used elements
    this.appContainer = document.querySelector(".app");
    this.yearSpans = document.querySelectorAll(".curyear");
    this.interBubble = document.querySelector(".interactive");
    this.aiLink = document.querySelector(".ai");
    this.aiMsg = document.querySelector(".ai-msg");
    this.mainCard = document.querySelector(".main-card");
    this.betaSignup = document.querySelector(".beta-signup");
    this.closeButton = document.querySelector(".close-button");
    this.openButton = document.querySelector(".open-button");
    this.aboutContainer = document.querySelector(".about-container");
    this.moreAbout = document.querySelector(".more-about");
    this.learnMore = document.querySelector(".text-link.more");
    this.startButton = document.querySelector(".start-button");
    this.startLink = document.querySelector(".start-link");
    this.stageTitle = document.querySelector(".stage-block.stage-block-main");
    this.signupContainer = document.querySelector(".signup-container");
    this.startContainer = document.querySelector(".start-container");
    this.signupStageBlocks = document.querySelectorAll(".signup-stage-blocks");
    this.beginButton = document.querySelector(".begin-button");

    // Cache form-specific elements
    this.formSlides = document.querySelectorAll(".form-slide");
    this.formNextButtons = document.querySelectorAll(".btn-next");
    this.formBackButtons = document.querySelectorAll(".btn-back");
    this.formInputControls = document.querySelectorAll(".input-control");

    // Cache stage blocks
    this.stageBlocks = document.querySelectorAll(".stage-block");
    this.stageEmail = document.querySelector(".stage-block.stage-email");
    this.stagePhone = document.querySelector(".stage-block.stage-phone");
    this.stageName = document.querySelector(".stage-block.stage-name");
    this.stagePassword = document.querySelector(".stage-block.stage-password");

    // Form Element By ID
    this.emailAddress = document.getElementById("emailAddress");
    this.verifyEmail = document.getElementById("verifyEmail");
    this.cellPhone = document.getElementById("cellPhone");
    this.verifyPhone = document.getElementById("verifyPhone");
    this.firstName = document.getElementById("firstName");
    this.lastName = document.getElementById("lastName");
    this.password = document.getElementById("password");
    this.passConfirm = document.getElementById("passConfirm");
  }

  // Helper method to check if critical elements exist
  validateCriticalElements() {
    return this.appContainer && this.interBubble;
  }

  // Helper method to update year spans
  updateYearSpans() {
    const currentYear = new Date().getFullYear();
    if (this.yearSpans?.length) {
      Array.from(this.yearSpans).forEach((span) => {
        span.textContent = currentYear;
      });
    }
  }

  // Method to remove main card
  removeMainCard() {
    this.mainCard?.remove();
    this.mainCard = null;
  }
}

// Modern DOM utility functions
export const DOM = {
  /**
   * Check if item is a DOM element
   */
  isElement(item) {
    return item instanceof Element || item instanceof Document;
  },

  /**
   * Find closest ancestor matching selector
   */
  findAncestor(element, selector) {
    return element.closest(selector);
  },

  /**
   * Get element's offset relative to document
   */
  getOffset(element) {
    const rect = element.getBoundingClientRect();
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
  },

  /**
   * Safely remove element(s) from DOM
   */
  remove(element) {
    if (Array.isArray(element)) {
      element.forEach((el) => el?.remove());
    } else {
      element?.remove();
    }
  },

  /**
   * Update browser history state
   */
  updateHistory(path, action = "push") {
    if (!window.history) return;

    const url = path === "main" ? "/" : path;
    if (action === "replace") {
      history.replaceState(null, null, url);
    } else {
      history.pushState(null, null, url);
    }
  },

  /**
   * Check if element is visible
   */
  isVisible(element) {
    if (!element) return false;
    const style = window.getComputedStyle(element);
    return (
      style.display !== "none" &&
      style.visibility !== "hidden" &&
      style.opacity !== "0"
    );
  },

  /**
   * Add event listener with automatic cleanup
   */
  addListener(element, event, handler, options = {}) {
    element.addEventListener(event, handler, options);
    return () => element.removeEventListener(event, handler, options);
  },

  /**
   * Safely query single or multiple elements
   */
  query(selector, context = document) {
    return context.querySelector(selector);
  },

  queryAll(selector, context = document) {
    return [...context.querySelectorAll(selector)];
  },
};
