// Generic module loader for Firesite
class ModuleLoader {
  constructor() {
    // Ensure singleton
    if (ModuleLoader.instance) {
      return ModuleLoader.instance;
    }
    ModuleLoader.instance = this;

    this.modules = new Map();
    this.mountPoints = new Map();
    this.domCache = null;
    this.initialized = false;
  }

  /**
   * Register a modules with its mount point
   * @param {string} name - Module name (e.g., 'signup', 'login')
   * @param {string} mountPoint - DOM element ID where modules should mount
   * @param {class} ModuleClass - The modules class to instantiate
   */
  register(name, mountPoint, ModuleClass) {
    console.log(`Registering module: ${name}`);
    this.modules.set(name, {
      mountPoint,
      ModuleClass,
      instance: null,
    });
  }

  /**
   * Initialize all registered modules that have mount points in the DOM
   * @returns {Promise<Map>} Map of initialized modules
   */
  async initializeModules() {
    if (this.initialized) {
      console.log("Modules already initialized");
      return this.getInitializedModules();
    }

    const initialized = new Map();

    // Ensure we have a DOM cache instance
    if (!this.domCache) {
      const { DOMCache } = await import("./utility/dom.js");
      this.domCache = new DOMCache();
    }

    console.log("Starting modules initialization with modules:", this.modules);

    for (const [name, config] of this.modules) {
      const mountElement = document.getElementById(config.mountPoint);
      if (mountElement) {
        console.log(`Initializing module: ${name}`);
        try {
          const instance = new config.ModuleClass();
          await instance.init();
          initialized.set(name, instance);
          this.mountPoints.set(name, mountElement);
          config.instance = instance;

          // Refresh DOM cache after each module initialization
          this.domCache.initCache();
        } catch (error) {
          console.error(`Failed to initialize module: ${name}`, error);
        }
      } else {
        console.log(`Mount point not found for module: ${name}`);
      }
    }

    this.initialized = true;
    return initialized;
  }

  /**
   * Get all initialized modules
   * @returns {Map} Map of initialized modules
   */
  getInitializedModules() {
    const initialized = new Map();
    for (const [name, config] of this.modules) {
      if (config.instance) {
        initialized.set(name, config.instance);
      }
    }
    return initialized;
  }

  /**
   * Get an initialized module by name
   * @param {string} name - Module name
   * @returns {object|null} Module instance or null if not found
   */
  getModule(name) {
    const module = this.modules.get(name);
    if (module?.instance) {
      return module.instance;
    }
    console.log(`Module ${name} not found or not initialized`, {
      modules: Array.from(this.modules.keys()),
    });
    return null;
  }

  /**
   * Check if a module is mounted
   * @param {string} name - Module name
   * @returns {boolean}
   */
  isMounted(name) {
    return this.mountPoints.has(name);
  }
}

// Create and export singleton instance
export const moduleLoader = new ModuleLoader();

// Example usage in main.js:
/*
import { SignupFlow } from './modules/signup/index.js';
import { moduleLoader } from './load.js';

// Register modules
moduleLoader.register('signup', 'betaSignup', SignupFlow);

// Initialize all modules
document.addEventListener('DOMContentLoaded', async () => {
  await moduleLoader.initializeModules();
}); 
*/
